@font-face {
  font-family: 'Work Sans';
  src: url('assets/fonts/WorkSans.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  src: url("assets/fonts/WorkSans-Medium.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Work Sans';
  src: url("assets/fonts/WorkSans-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

body {
  background: #f8f9fa !important;
  margin: 0px;
}

* {
  box-sizing: border-box;;
}